import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import posthog from "posthog-js";
// import * as Sentry from "@sentry/angular-ivy";
import "./polyfills";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
  enableProdMode();

  posthog.init(environment.posthogKey, {
    api_host: environment.posthogHost,
    person_profiles: "always",
    capture_pageview: false, // needed for angular -> specified in routing modules
  });

  // remove console.log output in production
  window.console.log = () => {};
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
