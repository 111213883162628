export const config = {
  apiUrlDev: "https://api-dev.radiobird.fm",
  apiUrlProd: "https://api.radiobird.fm",
  authUrlDev: "https://api-dev.radiobird.fm",
  authUrlProd: "https://api.radiobird.fm",
  // posthogKey: "phc_myhSkapYHNe4IHqUcWlEwu9a64LVnGSH5HoqqZ4sMD4",
  posthogKey: "phc_j5KaKIeZ7iNoqQHalZ6r1SouJFE1xiHJA0ueHbXQs85",
  // posthogHost: "https://posthog.radiobird.fm",
  posthogHost: "https://eu.i.posthog.com",
  authRoles: {
    sa: ["SA"], // Only Super Admin has access
    admin: ["SA", "Admin"], // Only SA & Admin has access
    editor: ["SA", "Admin", "Editor"], // Only SA & Admin & Editor has access
    user: ["SA", "Admin", "Editor", "User"], // Only SA & Admin & Editor & User has access
    guest: ["SA", "Admin", "Editor", "User", "Guest"], // Everyone has access
  },
};
