import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { UploadRoutingModule } from "./upload-routing.module";
import { UploadDetailComponent } from "./upload-detail/upload-detail.component";
import { SharedMaterialModule } from "app/shared/shared-material.module";
import { FlexLayoutModule } from '@angular/flex-layout';


import { PlyrModule } from "ngx-plyr";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { CrudService } from "app/views/cruds/crud.service";
import { ScrollingModule } from '@angular/cdk/scrolling';


@NgModule({
  declarations: [UploadDetailComponent],
  imports: [CommonModule, SharedMaterialModule, UploadRoutingModule, PlyrModule, ScrollingModule, ClipboardModule, FlexLayoutModule],
  providers: [CrudService],
})
export class UploadModule { }
