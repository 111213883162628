<div fxLayout="row wrap" fxLayout.lt-sm="column" class="pt-16 pl-16">
  <button mat-flat-button color="primary" (click)="goBack()">
    <mat-icon>chevron_left</mat-icon>
    Zurück zur Übersicht
  </button>
</div>

<div *ngIf="item">
  <div fxLayout="row wrap" fxLayout.lt-sm="column" class="pl-16">
    <div fxFlex="100" class="pr-16 pt-16" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center">
      <div class="pr-16">
        <h3>Dateiname</h3>
        <p style="word-break: break-all">
          {{ item.filename }}
        </p>
      </div>

      <div class="pr-16" fxHide.lt-sm>
        <h3>Erstellt am</h3>
        <p>
          {{
          item.created_at | date : "dd.MM.yy - HH:mm"
          }}
        </p>
      </div>
    </div>

    <div fxFlex="100" class="pr-16 pt-16">
      <div plyr [style]="isPhonePortrait ? '100vw' : '640px'" plyrType="audio" plyrTitle="Audio"
        [plyrPlaysInline]="true" [plyrSources]="audioSource" [plyrOptions]="plyrOptions" (plyrInit)="
          player = $event;
          setPlyrSource(
            jwtAuthService.getUserId(),
            item.filename
          )
        " (plyrPlay)="played($event)"></div>
      <!-- 
      <plyr style="display: block" [style]="isPhonePortrait ? '100vw' : '640px'" plyrType="audio" plyrTitle="Audio"
        [plyrPlaysInline]="true" [plyrSources]="audioSource" [plyrOptions]="plyrOptions" (plyrInit)="
          player = $event;
          setPlyrSource(
            jwtAuthService.getUserId(),
            item.filename
          )
        " (plyrPlay)="played($event)">
      </plyr> -->
    </div>

    <div fxFlex="100" class="pr-16 pt-16">
      <div fxLayout="row wrap" fxLayout.lt-sm="column">
        <!-- <div fxFlex="50" class="pr-16 pt-16" *ngIf="!isPhonePortrait">
          <h3>Transkription</h3>
        </div> -->
        <!-- <div
          [fxFlex]="isPhonePortrait ? 35 : 85"
          class="pr-16 pt-16"
        ></div> -->
        <div fxFlex="15" class="pr-16 pt-16">
          <!-- <button
            *ngIf="itemForm.controls['transcription'].value && !textTabPosition"
            mat-raised-button
            #tooltip="matTooltip"
            matTooltip="Inhalt kopiert!"
            matTooltipPosition="left"
            [matTooltipDisabled]="true"
            color="secondary"
            class="mr-16"
            [cdkCopyToClipboard]="itemForm.controls['transcription'].value"
            (click)="showTooltip(tooltip)"
          >
            Inhalt kopieren
            <mat-icon>content_copy</mat-icon>
          </button> -->
          <button mat-raised-button #tooltip="matTooltip" matTooltip="Inhalt kopiert!" matTooltipPosition="left"
            [matTooltipDisabled]="true" color="secondary" class="mr-16" [cdkCopyToClipboard]="
              copyTabText(
                item.transcription_raw,
                textTabPosition
              )
            " (click)="showTooltip(tooltip)">
            Inhalt kopieren
            <mat-icon>content_copy</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div fxFlex="100" class="pr-16 pt-16">
      <!-- <mat-form-field class="" style="width: 90%"> -->

      <mat-tab-group animationDuration="150ms" (selectedIndexChange)="matTabSelectedIndexChange($event)">
        <mat-tab label="Sprecherwechsel" class="button-tab">
          <cdk-virtual-scroll-viewport itemSize="50" class="vscroll-viewport">
            <div
              *cdkVirtualFor="let segment of createSpeakerChangeFormat(item.transcription_raw); let i = index; trackBy: trackByFn">
              <div class="segment-container pt-16" [ngClass]="{'highlighted': flashBorders[i]}">
                <span [ngStyle]="{'color': speakerColors[segment.speaker] || 'black'}" class="speaker-change-button"
                  (click)="playerJumpTo(segment.start, i)">
                  {{ segment.speaker }}
                  <mat-icon>play_arrow</mat-icon>
                  {{ createHHMMSS(segment.start) }}
                </span><br>
                <span>{{ segment.text }}</span>
              </div>
            </div>
          </cdk-virtual-scroll-viewport>
        </mat-tab>
        <mat-tab label="Formatiert / Absätze">
          <cdk-virtual-scroll-viewport itemSize="50" class="vscroll-viewport">
            <div class="pr-16 pt-16" [innerHTML]="createHyperFormat(item.transcription_raw)"></div>
          </cdk-virtual-scroll-viewport>
        </mat-tab>
        <mat-tab label="Formatiert / Zeilen">
          <cdk-virtual-scroll-viewport itemSize="50" class="vscroll-viewport">
            <div class="pr-16 pt-16"
              style="line-height: 1.75em; font-size: 1em; overflow-y: scroll; white-space: pre-line;">
              {{ createLinebrokenText(item.transcription) }}
            </div>
          </cdk-virtual-scroll-viewport>
        </mat-tab>
        <mat-tab label="VTT">
          <cdk-virtual-scroll-viewport itemSize="50" class="vscroll-viewport">
            <div class="pr-16 pt-16"
              style="line-height: 1.75em; font-size: 1em; white-space: pre-line; overflow-y: scroll;">
              {{ createVTT(item.transcription_raw) }}
            </div>
          </cdk-virtual-scroll-viewport>
        </mat-tab>
        <mat-tab label="Rohtext">
          <cdk-virtual-scroll-viewport itemSize="50" class="vscroll-viewport">
            <div class="pr-16 pt-16" style="line-height: 1.75em; font-size: 1em; overflow-y: scroll;">
              {{ item.transcription }}
            </div>
          </cdk-virtual-scroll-viewport>
        </mat-tab>
      </mat-tab-group>
      <!-- <textarea
          matInput
          name="transcription"
          matTextareaAutosize
          matAutosizeMinRows="3"
          matAutosizeMaxRows="47"
          [formControl]="itemForm.controls['transcription']"
          placeholder="Transcription"
        ></textarea> -->
      <!-- <textarea
          matInput
          name="transcription"
          matTextareaAutosize
          matAutosizeMinRows="3"
          matAutosizeMaxRows="47"
          [formControl]="itemForm.controls['transcription']"
          style="line-height: 1.75em; color: black; text-align: justify"
        ></textarea> -->
      <!-- </mat-form-field> -->
    </div>

    <!-- 
    <div fxFlex="50" class="pr-16 pt-16">
      <button
        *ngIf="itemForm.controls['transcription'].value"
        mat-raised-button
        #tooltip="matTooltip"
        matTooltip="Text kopiert!"
        matTooltipPosition="left"
        [matTooltipDisabled]="true"
        color="secondary"
        class="mr-16"
        [cdkCopyToClipboard]="itemForm.controls['transcription'].value"
        (click)="showTooltip(tooltip)"
      >
        Text kopieren
        <mat-icon>open_in_new</mat-icon>
      </button>
    </div> -->

    <!--
    <div fxFlex="50" class="pr-16 pt-16">
      <mat-form-field class="full-width">
        <input
          matInput
          name="address"
          [formControl]="itemForm.controls['address']"
          placeholder="address"
        />
      </mat-form-field>
    </div>
    <div fxFlex="50" class="pr-16 pt-16">
      <mat-form-field class="full-width">
        <input
          matInput
          name="balance"
          [formControl]="itemForm.controls['balance']"
          placeholder="Balance"
        />
      </mat-form-field>
    </div> -->
    <!-- <div fxFlex="50" class="pt-16 pr-16">
      <mat-slide-toggle [formControl]="itemForm.controls['isActive']"
        >Active Customer</mat-slide-toggle
      >
    </div> -->

    <div fxFlex="100" class="mt-16">
      <!-- <button mat-raised-button color="primary" [disabled]="itemForm.invalid">
        {{ "SAVE" | translate }}
      </button> -->
      <!-- <button
        mat-raised-button
        color="warn"
        type="button"
        (click)="dialogRef.close(false)"
      >
        Schließen
      </button> -->

      <span fxFlex></span>
    </div>
  </div>
</div>