import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Apollo, QueryRef, gql } from "apollo-angular";
import { AppLoaderService } from "../../../shared/services/app-loader/app-loader.service";
import { Subscription } from "rxjs";
import { JwtAuthService } from "../../../shared/services/auth/jwt-auth.service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { MatTooltip } from "@angular/material/tooltip";
import { environment } from "environments/environment";
import { PlyrComponent, PlyrModule } from "ngx-plyr";
import Vtt from "vtt-creator";

@Component({
  selector: "app-upload-detail",
  templateUrl: "./upload-detail.component.html",
  styleUrls: ["./upload-detail.component.scss"],
})
export class UploadDetailComponent implements OnInit {
  uploadId: string;
  uploadDataQuery: QueryRef<any>;
  querySubscription: Subscription;
  item: any;
  loading: boolean;
  error: any;
  public isPhonePortrait: Boolean = false;
  player: Plyr;
  @ViewChild(PlyrComponent) plyr: PlyrComponent;
  textTabPosition: number = 0;
  flashBorders: boolean[] = []; // Array to track flash state for each button

  audioSource: Plyr.Source[] = [
    {
      src:
        environment.apiURL +
        "/public/" +
        this.jwtAuthService.getUserId() +
        "/A-point_19-05-2022-1140_yoyxd53.1652955185991.mp3",
      type: "audio/mp3",
    },
  ];

  plyrOptions = {
    speed: {
      selected: 1,
      options: [1, 1.5],
    },
    controls: [
      "play",
      "current-time",
      "progress",
      "mute",
      "volume",
      "settings",
    ],
    invertTime: false,
    toggleInvert: true,
  };

  QUERY_FILE_DATA = gql`
    query MyQuery($_eq: uuid, $_gte: timestamptz) {
      files(where: { id: { _eq: $_eq } }) {
        id
        filename
        transcription
        transcription_raw
        created_at
      }
    }
  `;

  flashBorder: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apollo: Apollo,
    private loader: AppLoaderService,
    public jwtAuthService: JwtAuthService,
    private breakpointObserver: BreakpointObserver
  ) { }

  ngOnInit() {
    this.loader.open("loading");
    this.route.params.subscribe((params) => {
      this.uploadId = params["id"];
      // Fetch the upload details using this.uploadId
      console.log(this.uploadId);
      this.getUploadData();
    });

    this.breakpointObserver
      .observe(Breakpoints.HandsetPortrait)
      .subscribe((result) => {
        this.isPhonePortrait = false;
        this.plyrOptions = {
          speed: {
            selected: 1,
            options: [1, 1.5],
          },
          controls: [
            "play",
            "current-time",
            "progress",
            "mute",
            "volume",
            "settings",
          ],
          invertTime: false,
          toggleInvert: true,
        };
        if (result.matches) {
          this.isPhonePortrait = true;
          this.plyrOptions = {
            speed: {
              selected: 1,
              options: [],
            },
            controls: ["play", "current-time", "progress"],
            invertTime: false,
            toggleInvert: true,
          };
        }
      });
  }

  ngAfterViewInit() {
    this.uploadDataQuery.refetch();
  }

  goBack() {
    this.router.navigate(["/cruds/overview"]);
  }

  getUploadData() {
    this.uploadDataQuery = this.apollo.watchQuery({
      query: this.QUERY_FILE_DATA,
      variables: {
        _eq: this.uploadId,
      },
    });
    this.querySubscription = this.uploadDataQuery.valueChanges.subscribe(
      (result: any) => {
        console.log(result);
        this.item = result?.data?.files[0];
        this.loading = result.loading;
        this.error = result.error;
        console.log("data", this.item);
        this.loader.close();
      }
    );
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }

  speakerColors = {
    'SPEAKER_01': 'rgb(184 134 11)',
    'SPEAKER_02': 'rgb(128 0 128)',
    'SPEAKER_03': 'rgb(0 100 0)',
    'SPEAKER_04': 'rgb(0 139 139)',
    'SPEAKER_05': 'rgb(255 140 0)'
  };


  speakerColorsTransparent = {
    'SPEAKER_01': 'rgba(184, 134, 11, 0.05)',
    'SPEAKER_02': 'rgba(128, 0, 128, 0.05)',
    'SPEAKER_03': 'rgba(0, 100, 0, 0.05)',
    'SPEAKER_04': 'rgba(0, 139, 139, 0.05)',
    'SPEAKER_05': 'rgba(255, 140, 0, 0.05)'
  };


  play(): void {
    console.log("SOURCE", this.player.source);
    this.player.play(); // or this.plyr.player.play()
  }

  played(event: Plyr.PlyrEvent) {
    console.log("played", event);
  }

  playerJumpTo(startTime: number, index: number) {
    console.log("JUMP TO", startTime);
    this.player.pause();

    // // Wait for the pause to complete before playing
    // this.player.on('pause', () => {
    this.player.currentTime = startTime;
    this.player.play();
    this.triggerBorderAnimation(index);
    // });

  }

  triggerBorderAnimation(index: number) {
    this.flashBorders[index] = true; // Set the specific button to flash
    setTimeout(() => {
      this.flashBorders[index] = false; // Reset after animation duration
    }, 1000); // Duration should match the CSS animation duration
  }

  setPlyrSource(userId, filename) {
    this.audioSource = [
      {
        src: environment.apiURL + "/public/" + userId + "/" + filename,
        type: "audio/mp3",
      },
    ];
    console.log("SRC SET", this.audioSource);
  }

  showTooltip(tooltip: MatTooltip) {
    tooltip.disabled = false;
    tooltip.show();

    setTimeout(() => {
      tooltip.hide();
      tooltip.disabled = true;
    }, 2000);
  }

  createHyperFormat(transcription_raw: any) {
    let srt = this.createVTT(transcription_raw);

    // remove WEBVTT and second line at the beginning
    let lines = srt.split("\n");
    lines.splice(0, 2);
    srt = lines.join("\n");

    return this.parseSRT(srt);
  }

  createVTT(transcription_raw: any) {
    let vtt = new Vtt();
    let transcriptionJSON = transcription_raw;
    if (typeof transcription_raw === "string") {
      transcriptionJSON = JSON.parse(transcription_raw);
    }
    // console.log("transcriptionJSON");
    // console.log(transcriptionJSON);

    transcriptionJSON.segments.forEach((segment) => {
      // vtt.add(segment[0], segment[1], segment[2]);

      // new json format @diarization
      vtt.add(segment.start, segment.end, segment.text);
    });

    return vtt.toString();
  }

  // createSpeakerChangeFormat(transcription_raw: any) {
  //   // console.log("transcription_raw", transcription_raw);
  //   let result = "";
  //   if (typeof transcription_raw === "string") {
  //     transcription_raw = JSON.parse(transcription_raw);
  //   }

  //   // transcription_raw.segments.forEach((segment) => {
  //   //   // console.log(`${segment.speaker}: ${segment.text}`);
  //   //   result += `${segment.speaker}\n`;
  //   //   result += "\n";
  //   //   result += `${segment.text}\n`;
  //   // });

  //   transcription_raw.segments.forEach((segment, index) => {
  //     // Add line break between segments
  //     if (index > 0) {
  //       result += "\n";
  //     }
  //     // Add speaker label only if it changes
  //     if (
  //       index === 0 ||
  //       segment.speaker !== transcription_raw.segments[index - 1].speaker
  //     ) {
  //       result += "\n";
  //       result +=
  //         `</br></br><b>${segment.speaker}:` +
  //         `--> <div (click)="playerJumpTo(${segment.start})">${this.createHHMMSS(
  //           segment.start
  //         )}</div></b></br>` +
  //         `\n`;
  //       // result += "\n";
  //     }
  //     // Add text
  //     result += segment.text.trimStart();
  //   });

  //   return result;
  // }

  createSpeakerChangeFormat(transcription_raw: any) {
    let segments = [];
    if (typeof transcription_raw === "string") {
      transcription_raw = JSON.parse(transcription_raw);
    }

    transcription_raw.segments.forEach((segment) => {
      // Check if the last segment has the same speaker
      if (segments.length > 0 && segments[segments.length - 1].speaker === segment.speaker) {
        // Append the text to the last segment's text
        segments[segments.length - 1].text += ' ' + segment.text.trimStart();
      } else {
        // Add a new segment
        segments.push({
          speaker: segment.speaker,
          text: segment.text.trimStart(),
          start: segment.start,
        });
      }
    });

    return segments;
  }


  createHHMMSS(start: number) {
    const hours = Math.floor(start / 3600);
    const minutes = Math.floor((start % 3600) / 60);
    const seconds = Math.floor(start % 60);

    if (hours > 0) {
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    } else {
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    }
  }

  matTabSelectedIndexChange(position: number) {
    this.textTabPosition = position;
  }

  createLinebrokenText(transcription: string) {
    // new api returns in linebroken format -> currently no need for the below
    // const sentences = sbd.sentences(transcription);
    // return sentences.join("\n");

    return transcription;
  }

  copyTabText(transcription_raw: any, textTabPosition: number) {
    let textToCopy = "";
    switch (textTabPosition) {
      case 1:
        // build up string to copy out of the html-tags
        const parser = new DOMParser();
        const doc = parser.parseFromString(
          this.createHyperFormat(transcription_raw),
          "text/html"
        );
        const paragraphs = doc.querySelectorAll("p");
        // console.log("paragraphs", paragraphs);
        const textArray = Array.from(paragraphs).map((paragraph) =>
          paragraph.textContent.trim()
        );
        textArray.forEach((element) => {
          // const inputString = element
          //   .replace(/\n/g, "")
          //   .replace(/\n\s*\n/g, "");
          let inputString = element.trim().replace(/\n|\n\s*\n/g, "");
          inputString = inputString.replace(/  /g, " ");
          textToCopy += inputString + "\n \n";
        });
        break;
      case 2:
        textToCopy = this.createLinebrokenText(this.item.transcription);
        break;
      case 3:
        textToCopy = this.createVTT(transcription_raw);
        break;
      case 4:
        textToCopy = this.item.transcription.replace(/(\r\n|\n|\r)/gm, " ");
        break;
      default:
        let segmentedText = this.createSpeakerChangeFormat(transcription_raw);
        segmentedText.forEach(segment => {
          textToCopy += segment["speaker"] + " // " + this.createHHMMSS(segment["start"]) + "\n" + segment["text"] + "\n \n";
        });
    }
    return textToCopy;
  }

  // taken from here: https://github.com/hyperaudio/ha-converter/blob/master/src/converter.js
  // and rewritten for angular/TS
  parseSRT(data): string {
    // sets the offset/time on which the paragraph will split
    let paraSplitTime = 0.8;
    // "Only split paragraphs on text finishing with punctuation. (. ! ?)"
    let paraPunct = true;

    let i = 0,
      len = 0,
      idx = 0,
      lines,
      time,
      text,
      sub;

    // Simple function to convert HH:MM:SS,MMM or HH:MM:SS.MMM to SS.MMM
    // Assume valid, returns 0 on error
    let toSeconds = function (t_in) {
      let t = t_in.split(":");

      try {
        let s = t[2].split(",");

        // Just in case a . is decimal seperator
        if (s.length === 1) {
          s = t[2].split(".");
        }

        return (
          parseFloat(t[0]) * 3600 +
          parseFloat(t[1]) * 60 +
          parseFloat(s[0]) +
          parseFloat(s[1]) / 1000
        );
      } catch (e) {
        return 0;
      }
    };

    let outputString = "<article><section><p>";
    let lineBreaks = true;
    let ltime = 0;
    let ltext;

    // Here is where the magic happens
    // Split on line breaks
    lines = data.split(/(?:\r\n|\r|\n)/gm);
    len = lines.length;

    for (i = 0; i < len; i++) {
      sub = {};
      text = [];

      sub.id = parseInt(lines[i++], 10);

      // Split on '-->' delimiter, trimming spaces as well
      try {
        time = lines[i++].split(/[\t ]*-->[\t ]*/);
      } catch (e) {
        alert("Warning. Possible issue on line " + i + ": '" + lines[i] + "'.");
        break;
      }

      sub.start = toSeconds(time[0]);

      // So as to trim positioning information from end
      if (!time[1]) {
        // alert("Warning. Issue on line " + i + ": '" + lines[i] + "'.");
        return;
      }

      idx = time[1].indexOf(" ");
      if (idx !== -1) {
        time[1] = time[1].substr(0, idx);
      }
      sub.end = toSeconds(time[1]);

      // Build single line of text from multi-line subtitle in file
      while (i < len && lines[i]) {
        text.push(lines[i++]);
      }

      // Join into 1 line, SSA-style linebreaks
      // Strip out other SSA-style tags
      sub.text = text
        .join("\\N")
        .replace(/\{(\[\w]+\(?([\w\d]+,?)+\)?)+\}/gi, "");

      // Escape HTML entities
      sub.text = sub.text.replace(/</g, "&lt;").replace(/>/g, "&gt;");

      // Unescape great than and less than when it makes a valid html tag of a supported style (font, b, u, s, i)
      // Modified version of regex from Phil Haack's blog: http://haacked.com/archive/2004/10/25/usingregularexpressionstomatchhtml.aspx
      // Later modified by kev: http://kevin.deldycke.com/2007/03/ultimate-regular-expression-for-html-tag-parsing-with-php/
      sub.text = sub.text.replace(
        /&lt;(\/?(font|b|u|i|s))((\s+(\w|\w[\w\-]*\w)(\s*=\s*(?:\".*?\"|'.*?'|[^'\">\s]+))?)+\s*|\s*)(\/?)&gt;/gi,
        "<$1$3$7>"
      );
      //sub.text = sub.text.replace( /\\N/gi, "<br />" );
      sub.text = sub.text.replace(/\\N/gi, " ");

      let splitMode = 0;

      let wordLengthSplit = false;

      // enhancements to take account of word length
      let swords = sub.text.split(" ");
      let sduration = sub.end - sub.start;
      let stimeStep = sduration / swords.length;

      // determine length of words
      let swordLengths = [];
      let swordTimes = [];

      let totalLetters = 0;
      for (let si = 0, sl = swords.length; si < sl; ++si) {
        totalLetters = totalLetters + swords[si].length;
        swordLengths[si] = swords[si].length;
      }

      let letterTime = sduration / totalLetters;
      let wordStart = 0;

      for (let si = 0, sl = swords.length; si < sl; ++si) {
        let wordTime = swordLengths[si] * letterTime;
        let stime;
        if (wordLengthSplit) {
          stime = Math.round((sub.start + si * stimeStep) * 1000);
          // let event = new CustomEvent("ga", {
          //   detail: {
          //     origin: "HA-Converter",
          //     type: "Setting",
          //     action: "Word length split ON",
          //   },
          // });
          // document.dispatchEvent(event);
        } else {
          stime = Math.round((wordStart + sub.start) * 1000);
          // let event = new CustomEvent("ga", {
          //   detail: {
          //     origin: "HA-Converter",
          //     type: "Setting",
          //     action: "Word length split OFF",
          //   },
          // });
          // document.dispatchEvent(event);
        }

        wordStart = wordStart + wordTime;
        let stext = swords[si];

        if (stime - ltime > paraSplitTime * 1000 && paraSplitTime > 0) {
          //console.log("fullstop? "+stext+" - "+stext.indexOf("."));
          let punctPresent =
            ltext &&
            (ltext.indexOf(".") > 0 ||
              ltext.indexOf("?") > 0 ||
              ltext.indexOf("!") > 0);
          if (!paraPunct || (paraPunct && punctPresent)) {
            outputString += "</p><p>";
          }
        }

        outputString += '<span data-m="' + stime + '">' + stext + " </span>";

        ltime = stime;
        ltext = stext;

        if (lineBreaks) outputString = outputString + "\n";
      }
    }
    return outputString + "</p></section></article>";
  }

  trackByFn(index, item) {
    return item.start;
  }

}