import { NgModule } from "@angular/core";
import { Routes, Router, RouterModule, NavigationEnd } from "@angular/router";
import { UploadDetailComponent } from "./upload-detail/upload-detail.component";
import posthog from "posthog-js";
import { CommonModule } from "@angular/common";

const routes: Routes = [
  {
    path: ":id",
    data: { title: "Upload Details", breadcrumb: "{{id}}" },
    children: [
      {
        path: "",
        component: UploadDetailComponent,
      },
      // {
      //   path: "more",
      //   component: UploadCostListComponent,
      //   data: { title: "Uploads-more", breadcrumb: "Uploads-more" }
      // }
    ],
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UploadRoutingModule {
  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        posthog.capture("$pageview");
      }
    });
  }
}
