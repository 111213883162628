import { Injectable } from "@angular/core";
import { LocalStoreService } from "../local-store.service";
import { HttpClient } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { map, catchError, delay } from "rxjs/operators";
import { User } from "../../models/user.model";
import { of, BehaviorSubject, throwError } from "rxjs";
import { environment } from "environments/environment";
import { ConsoleLogger } from "@angular/compiler-cli/private/localize";
// import * as Sentry from "@sentry/angular-ivy";
import SuperTokens from "supertokens-website";
import posthog from "posthog-js";

// ================= only for demo purpose ===========
const DEMO_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1YjhkNDc4MDc4NmM3MjE3MjBkYzU1NzMiLCJlbWFpbCI6InJhZmkuYm9ncmFAZ21haWwuY29tIiwicm9sZSI6IlNBIiwiYWN0aXZlIjp0cnVlLCJpYXQiOjE1ODc3MTc2NTgsImV4cCI6MTU4ODMyMjQ1OH0.dXw0ySun5ex98dOzTEk0lkmXJvxg3Qgz4ed";

let DEMO_USER: User = {
  id: "5b700c45639d2c0c54b354ba",
  displayName: "Transkription Audio",
  role: "user",
};
// ================= you will get those data from server =======

@Injectable({
  providedIn: "root",
})
export class JwtAuthService {
  token;
  isAuthenticated: Boolean;
  sessionExists: Boolean = false;
  user: User = {};
  user$ = new BehaviorSubject<User>(this.user);
  signingIn: Boolean;
  return: string;
  JWT_TOKEN = "JWT_TOKEN";
  APP_USER = "EGRET_USER";

  constructor(
    private ls: LocalStoreService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe(
      (params) => (this.return = params["return"] || "/")
    );
  }

  public signin(username, password) {
    // FOLLOWING CODE SENDS SIGNIN REQUEST TO SERVER
    let signInObject = {
      formFields: [
        {
          id: "email",
          value: username,
        },
        {
          id: "password",
          value: password,
        },
      ],
    };

    this.signingIn = true;
    return (
      this.http
        // .post(`${environment.authURL}/auth/signin`, { username, password })
        .post(`${environment.authURL}/auth/signin`, signInObject)
        .pipe(
          map((res: any) => {
            console.log(res);
            console.log(res.status);
            if (res.status === "FIELD_ERROR") {
              console.log("ERROR");
              throw new Error("Emailadresse ist ungültig");
            }
            if (res.status === "WRONG_CREDENTIALS_ERROR") {
              throw new Error(
                "Logindaten sind ungültig. Bitte Username und Passwort überprüfen"
              );
            }
            this.setUserId(res.user.id);
            DEMO_USER.displayName = res.user.email;
            this.setUserAndToken(DEMO_TOKEN, res.user.email, !!res);

            // removed sentry due to privacy concerns
            // add user to sentry to identify
            // except for redaktion@oeamtc.at -> special privacy needs
            // if (res.user.id !== "cd587bf4-305d-413f-8e37-866b6e24cc23") {
            //   Sentry.setUser({ id: res.user.id });
            //   Sentry.setUser({ email: res.user.email });
            // }

            posthog.identify(
              res.user.id,
              { email: res.user.email } // optional: set additional person properties
            );

            this.signingIn = false;
            return res;
          }),
          catchError((error) => {
            return throwError(error);
          })
        )
    );
  }

  async doesSessionExist() {
    if (await SuperTokens.doesSessionExist()) {
      this.sessionExists = true;
    } else {
      this.sessionExists = false;
    }
  }

  /*
    checkTokenIsValid is called inside constructor of
    shared/components/layouts/admin-layout/admin-layout.component.ts
  */
  public checkTokenIsValid() {
    return of(DEMO_USER).pipe(
      map((profile: User) => {
        this.setUserAndToken(this.getJwtToken(), profile, true);
        this.signingIn = false;
        return profile;
      }),
      catchError((error) => {
        return of(error);
      })
    );

    /*
      The following code get user data and jwt token is assigned to
      Request header using token.interceptor
      This checks if the existing token is valid when app is reloaded
    */

    // return this.http.get(`${environment.authURL}/api/users/profile`)
    //   .pipe(
    //     map((profile: User) => {
    //       this.setUserAndToken(this.getJwtToken(), profile, true);
    //       return profile;
    //     }),
    //     catchError((error) => {
    //       this.signout();
    //       return of(error);
    //     })
    //   );
  }

  public signout() {
    this.setUserAndToken(null, null, false);
    // Sentry.setUser(null);
    posthog.reset();
    this.router.navigateByUrl("sessions/signin");
  }

  isLoggedIn(): Boolean {
    return !!this.getJwtToken();
  }

  // async isLoggedIn(): Promise<Boolean> {
  //   // return !!this.getJwtToken();
  //   await this.doesSessionExist();
  //   return this.sessionExists;
  // }

  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }

  getUser() {
    return this.ls.getItem(this.APP_USER);
  }

  getUserId() {
    // return DEMO_USER.id;
    return this.ls.getItem("id");
  }

  setUserId(id) {
    this.ls.setItem("id", id);
    DEMO_USER.id = id;
  }

  setUserAndToken(
    token: String = DEMO_TOKEN,
    user: User,
    isAuthenticated: Boolean
  ) {
    console.log("token", token);
    console.log("user", user);
    console.log("isAuthenticated", isAuthenticated);
    this.isAuthenticated = isAuthenticated;
    // this.token = token;
    this.token = token;
    this.user = user;
    this.user$.next(user);
    this.ls.setItem(this.JWT_TOKEN, token);
    this.ls.setItem(this.APP_USER, user);
  }
}
