import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subscription } from "rxjs";
import { Apollo, gql } from "apollo-angular";
import { UserDB } from "../../shared/inmemory-db/users";
import { Observable, of } from "rxjs";
import { delay } from "rxjs/operators";
import { JwtAuthService } from "../../shared/services/auth/jwt-auth.service";

@Injectable()
export class CrudService {
  items: any[];
  loading = true;
  error = null;
  filesAmount: Number = 0;
  private querySubscription: Subscription;

  GET_FILESAMOUNT = gql`
    query MyQuery($_eq: uuid = "") {
      files_aggregate(where: { user_id: { _eq: $_eq } }) {
        aggregate {
          count
        }
      }
    }
  `;

  constructor(
    private http: HttpClient,
    private apollo: Apollo,
    private jwtAuthService: JwtAuthService
  ) {
    let userDB = new UserDB();
    // this.items = userDB.users;
  }

  ngOnInit() {
    console.log("WE DO INIT");
    // this.fetchFiles();
    this.getFilesAmount();
  }

  getFiles() {
    return this.apollo
      .watchQuery({
        query: gql`
          query MyQuery {
            files {
              filepath
              id
              created_at
            }
          }
        `,
      })
      .valueChanges.subscribe((result: any) => {
        console.log(result);
        this.items = result?.data?.files;
        this.loading = result.loading;
        this.error = result.error;
        console.log("files", this.items);
      });
  }

  getFilesAmount() {
    this.querySubscription = this.apollo
      .watchQuery<any>({
        query: this.GET_FILESAMOUNT,
        variables: {
          _eq: this.jwtAuthService.getUserId(),
        },
      })
      .valueChanges.subscribe(({ data, loading }) => {
        this.loading = loading;
        this.filesAmount = data.files_aggregate.aggregate.count;
        console.log(this.filesAmount);
      });
  }

  getFilesAmountSub(): Observable<any> {
    return of(this.filesAmount);
  }

  //******* Implement your APIs ********
  getItems(): Observable<any> {
    return of(this.items.slice());
  }

  // getFiles(): Observable<any> {
  //   await this.fetchFiles();
  //   return of(this.items.slice());
  // }

  getFilesSimple() {
    return this.items;
  }

  addItem(item): Observable<any> {
    item._id = Math.round(Math.random() * 10000000000).toString();
    this.items.unshift(item);
    return of(this.items.slice()).pipe(delay(1000));
  }
  updateItem(id, item) {
    this.items = this.items.map((i) => {
      if (i._id === id) {
        return Object.assign({}, i, item);
      }
      return i;
    });
    return of(this.items.slice()).pipe(delay(1000));
  }
  removeItem(row) {
    let i = this.items.indexOf(row);
    this.items.splice(i, 1);
    return of(this.items.slice()).pipe(delay(1000));
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
